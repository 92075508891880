<!-- 已过期 -->
<template>
	<div class="all-send-car" :class="themeClass">
		<!-- 标题 -->
		<div class="title1 font-size18 font-weight700">已过期</div>
		<!-- 头部搜索部分 -->
		<!-- <div class="search flex-row-space-between">
			<div class="search-box flex-row-space-between-center">
				<el-input prefix-icon="el-icon-search" v-model="searchText"></el-input>
			</div>
			<div class="pointer search-btn text-center font-size13" @click="queryListData">搜索</div>
		</div> -->
		<div class="top flex-row">
			<div class="tabs">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane class="pointer-color-theme" label="全部日期" name="all"></el-tab-pane>
					<el-tab-pane class="pointer-color-theme" label="最近七天" name="sevendays"></el-tab-pane>
					<el-tab-pane class="pointer-color-theme" label="最近三个月" name="threemonth"></el-tab-pane>
					<el-tab-pane class="pointer-color-theme" label="最近一年" name="oneyear"></el-tab-pane>
				</el-tabs>
			</div>
			<div class="choose-date margin-l-10"  style="width: 418px;display: flex;align-items: center;">
				<!-- <el-date-picker v-model="searchDate" @change="queryListData" unlink-panels type="daterange" range-separator="至"
				 start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker> -->
				<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期"></el-date-picker>
				<div style="margin-left: 3px;margin-right: 3px;">至</div>
				<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期"></el-date-picker>
			</div>
			<div class="search-box margin-l-10">
				<el-input prefix-icon="el-icon-search" v-model.trim="searchText" placeholder="车号/派车单号/订单号"> </el-input>
			</div>
			<div class="pointer search-btn margin-l-10 text-center" @click="queryListData">搜索</div>
		</div>
		<send-car-list v-model="list" @queryList="queryListData"></send-car-list>
		<div class="pagination">
			<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total" @handleCurrentChange="handleCurrentChange"></pagination>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "../../../components/pagination/pagination";
	import sendCarList from "../../../components/business/sendCarList.vue";

	export default {
		name: "",
		components: {
			pagination,
			sendCarList
		},
		props: {},
		data() {
			return {
				searchText: "",
				list: [],
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0,
				activeName: "threemonth",
				searchDate: ['', ''],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			const start = new Date();
			const end = new Date();
			this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
			this.searchDate[1] = end
			this.queryListData();
		},
		methods: {
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', '']
				}
				const start = new Date();
				const end = new Date();
				switch (this.activeName) {
					case "all":
						this.searchDate[0] = ''
						this.searchDate[1] = ''
						break;
					case "sevendays":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 7))
						this.searchDate[1] = end
						break;
					case "threemonth":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
						this.searchDate[1] = end
						break;
					case "oneyear":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 365))
						this.searchDate[1] = end
						break;
				}
				this.currentPage = 1;
				this.queryListData();
			},
			// 当前页 改变时会触发
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.queryListData();
			},
			queryListData() {
				this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-list-by-current-customer-time-out', {
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: this.searchText,
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					this.total = res.obj.totalCount;
					res.obj.items.forEach(item => {
						item.fDeliveryAddress = item.fDeliveryAddress.replace(/\\/g, '');
						if (item.fCreateTime) {
							item.fCreateTime = this.getTime(item.fCreateTime)
						}
						if (item.fGrossTime) {
							item.fGrossTime = this.getTime(item.fGrossTime)
						}
						if (item.fTareTime) {
							item.fTareTime = this.getTime(item.fTareTime)
						}
						if (item.fCarrSignTime) {
							item.fCarrSignTime = this.getTime(item.fCarrSignTime)
						}
						if (item.fCustSignTime) {
							item.fCustSignTime = this.getTime(item.fCustSignTime)
						}
					})
					this.list = res.obj.items
				}, error => {});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.common-height {
		height: 35px;
		line-height: 35px;
	}

	.common {
		width: 100%;

		div {
			&:nth-child(1) {
				flex: 1;
			}

			&:nth-child(2) {
				width: 40%;

				div {
					flex: 1;
					text-align: center;
				}
			}
		}
	}

	.all-send-car {
		margin-top: 22px;
		width: 100%;

		.title1 {
			height: 24px;
			line-height: 24px;
			margin-bottom: 4px;
		}

		.search {
			width: 100%;

			.search-box {
				box-sizing: border-box;
				flex: 1;
				height: 32px;
				line-height: 32px;
			}

			/deep/ .el-input__prefix {
				margin-left: 5px;
			}

			/deep/ .el-input__icon {
				line-height: 32px;
			}

			/deep/ .el-input--prefix .el-input__inner {
				padding-left: 39px;
			}

			.search-btn {
				margin-left: 8px;
				width: 79px;
				height: 32px;
				line-height: 32px;
				background: #f8f7fa;
				border-radius: 4px;
				text-align: center;
			}
		}

		.pagination {
			float: right;
			margin-top: 20px;
		}
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	/deep/ .el-date-editor .el-range-separator {
		line-height: 24px;
	}

	.allOrder-Box {
		height: 100%;
		background-color: #ff3040;
	}

	.all-title {
		height: 24px;
		line-height: 24px;
		margin-bottom: 4px;
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	.title {
		width: 100%;
		height: 35px;
		background: #f8f9fe;
	}

	.content {
		.nav {
			width: 100%;
			height: 35px;
			line-height: 35px;
			background: #f8f9fe;

			span {
				display: block;
				text-align: center;

				&:nth-child(1) {
					width: 425px;
				}

				&:nth-child(n + 2) {
					width: 123px;
				}
			}
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}
</style>
